import React from 'react';

import { Button } from '../ui/button/button';
import { Modal, ModalMain } from '../ui/modal/modal';
import { Content,  } from './onboarding-dialog';

interface ConfirmDialogProps {
  cancelButtonTitle?: string;
  onCancel: () => void;

  onSubmit: () => void;
  open: boolean;
  setOpen: (open: boolean) => void;

  subTitle?: string;
  submitButtonTitle?: string;
  submitButtonVariant?: 'error' | 'primary';
  title?: string;
}
export const ConfirmDialog: React.FC<ConfirmDialogProps> = (props) => {
  return (
    <Modal 
      onOpenChange={(v) => {
        props.setOpen(v);
      }} 
      open={props.open}
    >
      <Content showClose={false} size='small'>
        <ModalMain className={'p-6 grid'}>
          <section className='grid grid-flow-row'>
            <div className='grid grid-flow-row gap-5'>
              <div className='grid grid-flow-row gap-3'>
                <h1 className='text-label-1-medium xs:font-sfProMedium sm:font-pretendardMedium text-gray-90'>
                  {props.title ? props.title : '선택한 내용을 저장하지 않고 나갈까요?'}
                </h1>
                <h2 className='text-body-2-regular xs:font-sfProLight sm:font-pretendardLight text-gray-60'>
                  {props.subTitle ? props.subTitle : '선택한 키워드가 적용되지 않습니다.'}
                </h2>
              </div>
              <div className='grid grid-flow-col gap-2'>
                <Button
                  onClick={props.onCancel}
                  rounded={'md'}
                  size={'medium'}
                  type='button'
                  variant={'gray'}
                >
                  {props.cancelButtonTitle ? props.cancelButtonTitle : '머무르기'}
                </Button>
                <Button
                  onClick={props.onSubmit}
                  rounded={'md'}
                  size={'medium'}
                  type='button'
                  variant={props.submitButtonVariant ?? 'primary'}
                >
                  {props.submitButtonTitle ? props.submitButtonTitle : '나가기'}
                </Button>
              </div>
            </div>
          </section>
        </ModalMain>
      </Content>
    </Modal>
  );
};
