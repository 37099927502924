/* eslint-disable complexity */
import type {action as SearchCareerKeywordAction} from '#app/routes/search-career-keyword';
import type { Keyword } from '@prisma/client';
import type { SerializeFrom } from '@remix-run/node';

import { careerAndCompanySchema } from '#app/action/career-and-company';
import { Field } from '#app/components/forms';
import { Button } from '#app/components/ui/button/button';
import { TextButton } from '#app/components/ui/button/text-button';
import { ComboBoxResponsive } from '#app/components/ui/combobox/combobox';
import { ModalCardImage, ModalMain } from '#app/components/ui/modal/modal';
import { useDebounce } from '#app/utils/misc';
import { track } from '#app/utils/track';
import { getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint } from '@conform-to/zod';
import { Form, useFetcher } from '@remix-run/react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { match } from 'ts-pattern';

import Career from '../../../../other/png/career.png';
import { Content, OnboardingStep } from '../onboarding-dialog';

interface OnboardingCareerAndCompanyProps {
  company?: string;
  job?: string;
  keywords: SerializeFrom<Keyword>[];
  onNext: (step: OnboardingStep, value?: {company?: string; job: string}) => void;
  onPrev: (step: OnboardingStep) => void;
  profile: {
    imageUrl: string;
    name: string;
  };
  step: OnboardingStep;
}
export const OnboardingCareerAndCompany: React.FC<OnboardingCareerAndCompanyProps> = ({ keywords, onNext, profile }) => {
  const searchCareerKeywords = useFetcher<typeof SearchCareerKeywordAction>();
  const handleFormChange = useDebounce((value: HTMLFormElement) => {
    void searchCareerKeywords.submit(value, {
      action: '/search-career-keyword',
      method: 'POST',
    });
  }, 200);

  const isFirstRender = React.useRef(false);

  const [form, fields] = useForm({
    constraint: getZodConstraint(careerAndCompanySchema),
    id        : OnboardingStep.CareerAndCompany,
  });
  const closeKeywordIds = keywords.filter((keyword) => keyword.isSpecific === false).map((keyword) => keyword.id) ?? [];
  const noCareer = keywords.find((keyword) => keyword.isOpen === false)?.id;
  const [selectedJob, setSelectedJob] = React.useState<string | undefined>(undefined);
  const [selectedKeywordId, setSelectedKeywordId] = React.useState<number | undefined>(undefined);
  const [company, setCompany] = React.useState('');
  const [displayJob, setDisplayJob] = React.useState('');
  const [errors, setErrors] = React.useState<null[] | string[]>([]);
  const [, transition] = React.useTransition();
  const [next, setNext] = React.useState<{
    hasCompany: boolean;
  } | undefined>(undefined);
  const [searchCareerKeyword, setSearchCareerKeywords] = React.useState(keywords);

  useEffect(() => {
    void track('view_onboarding_career_and_company');
  }, []);
  useEffect(() => {
    if(!searchCareerKeywords.data) {
      return;
    }
    setSearchCareerKeywords(searchCareerKeywords.data.keywords);
  }, [searchCareerKeywords.data]);

  console.log(searchCareerKeywords.state);

  return (
    <Content size={isMobile ? 'full' : 'medium'}>
      <Form id={form.id} method='post' navigate={false} onChange={(e) => {
        handleFormChange(e.currentTarget);
      }}>
        {next === undefined 
          ? 
          <ModalMain className={'px-[3.75rem] py-[3.75rem]'}>
            <section className='grid grid-flow-row gap-16 min-h-[25rem] content-between'>
              <div className='grid grid-flow-row gap-10 justify-items-center h-fit'>
                <span className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
                  {profile.name}님은
                  <br/>
                  무슨 일을 하시나요?
                </span>
                <div className='grid grid-flow-row gap-3 w-full'>
                  <ComboBoxResponsive
                    inputProps={{
                      autoFocus  : false,
                      placeholder: '직업을 선택해주세요',
                      ...getInputProps(fields.job, {type: 'text'}),
                    }}
                    items={searchCareerKeyword.map(
                      (keyword) => ({
                        id   : keyword.id,
                        label: keyword.name,
                        value: keyword.name,
                      }),
                    )}
                    loading={searchCareerKeywords.state !== 'idle'}
                    setSelected={(_item, type) => {
                      void setSelectedJob(_item.label);
                      void setSelectedKeywordId(_item.id);
                      if (type === 'click') {
                        if (isFirstRender.current === false) {
                          isFirstRender.current = true;
                          return;
                        }
                      }
                    }}
                  />
                  {!selectedJob && <TextButton 
                    className='mt-2'
                    data-umami-event='click_onboarding_career_and_company_no_job'
                    onClick={() => {
                      setNext({ hasCompany: false });
                      setSelectedJob('아직 직업이 없어요');
                      setSelectedKeywordId(29);
                    }} 
                    size={'medium'}
                    type='button' 
                    underline={'underline'}>
                    아직 직업이 없어요
                  </TextButton>}
                  {
                    match({
                      isCloseKeyword: closeKeywordIds.includes(selectedKeywordId!),
                      isNoCareer    : noCareer === selectedKeywordId ? true : false,
                      isSelectedJob : selectedJob ? true : false,
                    })
                      .with({
                        isCloseKeyword: true,
                        isNoCareer    : false,
                        isSelectedJob : true,
                      }, () => (
                        <div className='grid grid-flow-row gap-3'>
                          <Field
                            className='w-full'
                            errors={errors}
                            inputProps={{
                              autoFocus: true,
                              ...getInputProps(fields.displayJob, {type: 'text'}),
                              onChange : (e) => {
                                if (isFirstRender.current === false) {
                                  isFirstRender.current = true;
                                  return;
                                }
                                if (e.currentTarget.value.length > 20) {
                                  void setErrors(['20자 이내로 입력해주세요']);
                                } else {
                                  void setErrors([]);
                                }
                                void setDisplayJob(e.currentTarget.value);
                              },
                              onKeyDown: (e) => {
                                if (displayJob.length === 0 && (e.key === ' ' || e.keyCode === 32)) {
                                  e.preventDefault();
                                }
                              },
                              placeholder: '구체적인 직업을 알려주세요.',
                            }}
                          />
                          <div className='bg-gray-5 py-[0.625rem] px-[0.875rem] rounded-md'>
                            <span className='text-caption-1-medium xs:font-sfProMedium sm:font-pretendardMedium text-gray-70'>
                              {selectedJob === '기타' ? '회원님의 직업은' : `${selectedJob!}는`} 더 많은 사람들이 가입하면 세분화될 예정이에요.
                            </span>
                          </div>
                        </div>
                      ))
                      .with({
                        isCloseKeyword: false,
                        isNoCareer    : false,
                        isSelectedJob : true,
                      }, () => (
                        <Field
                          className='w-full'
                          errors={errors}
                          inputProps={{
                            autoFocus: true,
                            ...getInputProps(fields.company, {type: 'text'}),
                            onChange : (e) => {
                              if (isFirstRender.current === false) {
                                isFirstRender.current = true;
                                return;
                              }
                              if (e.currentTarget.value.length > 20) {
                                void setErrors(['20자 이내로 입력해주세요']);
                              } else {
                                void setErrors([]);
                              }
                              void setCompany(e.currentTarget.value);
                            },
                            onKeyDown: (e) => {
                              if (company.length === 0 && (e.key === ' ' || e.keyCode === 32)) {
                                e.preventDefault();
                              }
                            },
                            placeholder: '회사명 혹은 소속 이름을 입력해주세요',
                          }}
                        />
                      ))
                      .with({
                        isNoCareer: true,
                      }, () => null)
                      .otherwise(() => <></>)
                  }
                </div>
              </div>
              {selectedJob && 
            <div className='grid grid-flow-row gap-5'>
              <Button 
                disabled={!selectedJob || errors.length > 0 || (noCareer !== selectedKeywordId && company.length === 0 && displayJob.length === 0)}
                onClick={() => {
                  if (company) {
                    setNext({ hasCompany: true });
                  } else {
                    setNext({ hasCompany: false });
                  }
                }} rounded={'md'}
                size={'large'}
                variant={'primary'}>
                다음
              </Button>
              {!closeKeywordIds.includes(selectedKeywordId!) && noCareer !== selectedKeywordId && <div className='grid justify-center'>
                <TextButton
                  data-umami-event='click_onboarding_career_and_company_no_company_next'
                  onClick={() => {
                    setNext({hasCompany: false});
                  }} size='medium' underline={'underline'} variant={'gray'}>소속이 없습니다.</TextButton>
              </div>}
            </div>
              }
            </section>
          </ModalMain>
          :
          <>
            <div className='h-0 hidden'>
              <Field
                inputProps={{
                  value: selectedJob,
                  ...getInputProps(fields.job, {type: 'hidden'}),
                }} />
              <Field
                inputProps={{
                  value: company,
                  ...getInputProps(fields.company, {type: 'hidden'}),
                }} />
              <Field
                inputProps={{
                  value: displayJob,
                  ...getInputProps(fields.displayJob, {type: 'hidden'}),
                }} />
            </div>
            <div className='px-10 pt-10'>
              <ModalCardImage alt='career' className='h-[13.75rem]' src={Career} />
            </div>
            <ModalMain className='px-[3.75rem] py-[3.75rem]'>
              <section className='grid grid-flow-row gap-10'>
                <div className='grid grid-flow-row gap-4 text-center'>
                  <h1 className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
                    {next.hasCompany === true && <>{company}의<br/></>}
                    {
                      match(closeKeywordIds.includes(selectedKeywordId!))
                        .with(true, () => `${selectedKeywordId !== 29 ? `${displayJob}이군요!` : '아직 직업이 없으시군요!'}`)
                        .with(false, () => <>{selectedKeywordId !== 29  ? `${selectedJob!}이` : '아직 직업이 없으'}시군요!</>)
                        .otherwise(() => <></>)
                    }
                  </h1>
                  <h2 className='text-body-1-paragraph-medium xs:font-sfProMedium sm:font-pretendardMedium text-gray-60'>
                  북커버리에서 다양한 직업의 사람들이 
                    <br/>
                  함께 공유하는 책장을 만들어요.
                  </h2>
                </div>
                <div className='grid grid-flow-row gap-5'>
                  <Button
                    data-umami-event='click_onboarding_career_and_company_next'
                    onClick={() => {
                      transition(() => {
                        if(next.hasCompany) {
                          onNext(OnboardingStep.VerifyCompany, {
                            company,
                            job: selectedJob!,
                          });
                        } else {
                          onNext(OnboardingStep.FollowKeyword, {job: selectedJob!,});
                        }
                      });
                    }}
                    rounded={'md'}
                    size={'large'}
                    type='submit'
                    variant={'primary'}>
                  북커버리 시작하기
                  </Button>
                  <TextButton
                    data-umami-event='click_onboarding_career_and_company_reset'
                    onClick={() => {
                      void setNext(undefined);
                      void setSelectedJob(undefined);
                      void setCompany('');
                      isFirstRender.current = false;
                    }}
                    size={'medium'} underline={'underline'}>
                  다시 선택하기
                  </TextButton>
                </div>
              </section>
            </ModalMain>
          </> 
        }
      </Form>
    </Content>
  );
};