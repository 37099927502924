import type { Keyword } from '@prisma/client';

import { followKeywordSchema } from '#app/action/follow-keyword';
import { GlobalAction } from '#app/utils/global-action';
import { getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint } from '@conform-to/zod';
import { Form, useFetcher } from '@remix-run/react';
import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';

import { Field } from '../forms';
import { Button } from '../ui/button/button';
import Chip from '../ui/chip/chip';
import { Icon } from '../ui/icon';
import { ModalFooter, ModalHeader, ModalMain, ModalTitle } from '../ui/modal/modal';
import { ConfirmDialog } from './confirm-dialog';
import { Content, OnboardingStep } from './onboarding-dialog';


export interface FollowKeywordsProps {
  careerKeyword?: Keyword | null;
  groupedKeyword: Record<string, Keyword[]>;
  onNext: (step: OnboardingStep, value: {keywords: string[]}) => void;
  onPrev: (step: OnboardingStep) => void;

  selectedKeywords: Keyword[];
}
export const FollowKeywords: React.FC<FollowKeywordsProps> = ({ careerKeyword, groupedKeyword, onNext, selectedKeywords: propSelectedKeywords }) => {
  const filteredKeywords = [...propSelectedKeywords, careerKeyword].filter((k): k is Keyword => k?.isOpen === true && k?.id !== 29);
  const map = new Map(filteredKeywords.map(k => [k.id, k]));

  const ref = useRef<Keyword[]>(filteredKeywords);
  const [, transition] = React.useTransition();
  const [selectedKeywords, setSelectedKeywords] = React.useState<Set<Keyword>>(new Set([...map.values()]));

  const orderedGroupedKeyword = Object.entries(groupedKeyword).sort((a, b) => {
    if(a[0] === '기타') {
      return 1;
    }
    if(b[0] === '기타') {
      return -1;
    }
    return 0;
  });

  const [form, fields] = useForm({
    constraint: getZodConstraint(followKeywordSchema),
    id        : OnboardingStep.FollowKeyword,
  });

  const dirty = (): boolean => {
    const compare = (a: Keyword, b: Keyword) => {
      if(a.id < b.id) {
        return -1;
      }
      if(a.id > b.id) {
        return 1;
      }
      return 0;
    };
    
    const b = [...selectedKeywords].sort(compare).map(k => k.id);
    if (b.length===0) {
      return false;
    }
    if(ref.current !== undefined) {
      const r = ref.current?.sort(compare).map(k => k.id);
      return btoa(r.join('')) !== btoa(b.join('')) || selectedKeywords.size === 0;
    } 
    return false;
  };
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Content 
        onClose={(e) => {
          if (dirty()) {
            void setOpen(true);
            e.preventDefault();
            return;
          }
          // 하나만 선택했는데 그게 직업 키워드인 경우
          if (selectedKeywords.size === 1 && careerKeyword?.id === [...selectedKeywords][0]?.id) {
            e.preventDefault();
            return;
          }
          // 하나도 선택 안한 경우
          if (selectedKeywords.size === 0 && careerKeyword) {
            e.preventDefault();
            return;
          }
        }}
        onInteractOutside={(e) => {
          if (dirty()) {
            void setOpen(true);
            e.preventDefault();
            return;
          }
          // 하나도 선택 안한 경우
          if (selectedKeywords.size === 0 && careerKeyword) {
            e.preventDefault();
            return;
          }
          // 하나만 선택했는데 그게 직업 키워드인 경우
          if (selectedKeywords.size === 1 && careerKeyword?.id === [...selectedKeywords][0]?.id) {
            e.preventDefault();
            return;
          }
          e.preventDefault();
        }}
        showClose={true} size={isMobile ? 'full' : 'large'}>
        <Form className='h-[37.5rem] grid' id={form.id} method='post' navigate={false}>
          <ModalHeader>
            <ModalTitle className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
              관심 있는 키워드를 팔로우하세요
            </ModalTitle>
          </ModalHeader>        
          <ModalMain className={'px-10 py-5 overflow-auto grid grid-col-row gap-7'}>
            {
              orderedGroupedKeyword
                .map(([groupName, keywords], i) => {
                  return <GroupedKeyword careerKeyword={careerKeyword} groupName={groupName} key={i} keywords={keywords} onClick={(keyword) => {
                    const set = new Set<Keyword>(selectedKeywords);
                    if (careerKeyword?.id === keyword.id) {
                      return;
                    }
                    if([...set].some(k => k?.id === keyword?.id)) {
                      void set.delete(keyword);
                      set.forEach(k => {
                        if(k?.id === keyword?.id) {
                          void set.delete(k);
                        }
                      });
                      void setSelectedKeywords(set);
                      return;
                    }
                    void set.add(keyword);
                    void setSelectedKeywords(set);
                  }} 
                  selectedKeywords={selectedKeywords} 
                  />; 
                })
            }
          </ModalMain>
          {
            <div className='h-0 hidden'>
              {[...selectedKeywords].map(
                k => (
                  <Field
                    inputProps={{
                      value: k?.id,
                      ...getInputProps(fields.followKeywords, {type: 'hidden'}),
                    }}
                    key={k?.id}
                  />
                )
              )}
            </div>
          }
          <ModalFooter>
            <Button 
              data-umami-event='click_follow_keyword'
              disabled={selectedKeywords.size === 0}
              onClick={() => {
                transition(() => onNext(OnboardingStep.Agree, {keywords: [...selectedKeywords].map(k => k?.name)}));
              }} 
              rounded={'md'} 
              size={'medium'} 
              type='submit'
              variant={'primary'}>
              {selectedKeywords.size}개 선택 완료
            </Button>
          </ModalFooter>
        </Form>
      </Content>
      {open && <ConfirmDialog
        onCancel={() => {
          setOpen(false);
        }}
        onSubmit={() => {
          setOpen(false);
          transition(() => onNext(OnboardingStep.Agree, {keywords: [...selectedKeywords].map(k => k?.name)}));
        }}
        open={open} setOpen={(v) => setOpen(v)} 
      />
      }
    </>
  );
};


type Props = {
  careerKeyword: Keyword | null | undefined;
  groupName: string;
  keywords: Keyword[];
  onClick: (keyword: Keyword) => void;
  selectedKeywords: Set<Keyword>;
};
const GroupedKeyword: React.FC<Props> = ({careerKeyword, groupName, keywords, onClick, selectedKeywords}) => {
  const fetcher = useFetcher();
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    fetcher.submit(event.currentTarget, {method: 'POST', preventScrollReset: true});
  };

  return (
    <article className='grid grid-flow-row gap-3'>
      <div>
        <span className='grid grid-flow-col gap-1 justify-start text-subtitle-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
          <Icon name='regular-hashtag' />
          {groupName}
        </span>
      </div>
      <div className='flex flex-wrap gap-2'>
        {
          keywords.map((keyword, i) => {
            if (keyword.id === careerKeyword?.id) {
              return <fetcher.Form key={keyword.id} method='POST' onClick={onSubmit}>
                <Chip
                  key={keyword.id}
                  name={'chip-key'}
                  onClick={(e) => onClick(keyword)} 
                  value={keyword.name}
                  variant={[...selectedKeywords].some(k => k?.id === keyword?.id) === false ? 'gray' : 'black'}
                >
                  {keyword.name}
                </Chip>
                <input name={GlobalAction.RequireCareerKeyword} type='hidden' value={'true'} />
              </fetcher.Form>;
            }

            return <Chip
              key={keyword.id}
              name={'chip-key'}
              onClick={(e) => onClick(keyword)} 
              value={keyword.name}
              variant={[...selectedKeywords].some(k => k?.id === keyword?.id) === false ? 'gray' : 'black'}
            >
              {keyword.name}
            </Chip>;
          })
        }
      </div>
    </article>
  );
};
