/* eslint-disable max-lines */
import type { Keyword,  } from '@prisma/client';
import type * as DialogPrimitive from '@radix-ui/react-dialog';
import type { SerializeFrom } from '@remix-run/node';
import type { PropsWithChildren } from 'react';

import { verifyCompanySchema } from '#app/action/verify-company';
import { GlobalDialog } from '#app/utils/global-dialog.ts';
import { logger } from '#app/utils/logger';
import { track } from '#app/utils/track';
import { getInputProps, useForm } from '@conform-to/react';
import { getZodConstraint } from '@conform-to/zod';
import { Form, useSearchParams } from '@remix-run/react';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { z } from 'zod';

import { Field } from '../forms';
import { Button } from '../ui/button/button';
import { TextButton } from '../ui/button/text-button';
import { Modal, ModalContent, ModalMain } from '../ui/modal/modal';
import { FollowKeywords } from './follow-keywords';
import { OnboardingCareerAndCompany } from './onboarding-dialog/onboarding-career-and-company';

export enum OnboardingStep {
  Agree = 'agree',
  CareerAndCompany = 'career-and-company',
  FollowKeyword = 'follow-keyword',
  Profile = 'profile',
  SendEmail = 'send-email',
  VerifyCompany = 'verify-company',
}

interface OnboardingDialogProps {
  /**
   * 직군 선택
   */
  careerKeyword?: Keyword | null;
  /**
   * 회사이름
   */
  companyName?: null | string;
  /**
   * 이메일
   */
  email: string;
  /**
   * 팔로우 키워드
   */
  followKeywords: Keyword[];
  /**
   * 그룹핑 키워드
   */
  groupedKeyword: Record<string, Keyword[]>;
  /**
   * 프로필 이미지
   */
  imageUrl: string;
  keywords: SerializeFrom<Keyword>[]
  /**
   * 이름
   */
  name: string;
  
}
const OnboardingDialog: React.FC<OnboardingDialogProps> = ({ careerKeyword, companyName, email, followKeywords, groupedKeyword, imageUrl, keywords, name }) => {
  const payload = React.useRef({
    careerKeyword,
    companyName,
    job    : careerKeyword?.name,
    profile: {
      email,
      imageUrl,
      name,
    }
  });

  const [, transition] = React.useTransition();
  const stepSchema = z
    .nativeEnum(OnboardingStep)
    .default(OnboardingStep.Profile);

  const [searchParams, setSearchParams] = useSearchParams();
  const parsedStep = stepSchema.safeParse(searchParams.get(GlobalDialog.OnboardingStep));
  const [open, setOpen] = React.useState(searchParams.has(GlobalDialog.Onboarding));
  const [step, setStep] = React.useState<OnboardingStep>((parsedStep.success && parsedStep.data) || OnboardingStep.Profile);

  const onNext = (step: OnboardingStep) => {
    const params = new URLSearchParams();
    void setStep(step);
    void params.set(GlobalDialog.Onboarding, '');
    void params.set(GlobalDialog.OnboardingStep, step);
    void setSearchParams(params);
  };
  
  void React.useEffect(() => {
    void setOpen(searchParams.has(GlobalDialog.Onboarding));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Modal
      onOpenChange={(value) => {
        const params = new URLSearchParams();
        if (value) {
          void params.set(GlobalDialog.Onboarding, '');
          void setSearchParams(params);
        } else {
          void params.delete(GlobalDialog.Onboarding);  
          void params.delete(GlobalDialog.OnboardingStep);  
          void setSearchParams(params);
        }
        return value;
      }}
      open={open}>
      {step === OnboardingStep.Profile && <Profile 
        onNext={onNext}
        onPrev={() => {}}
        profile={payload.current.profile}
        step={step} />}
      {step === OnboardingStep.CareerAndCompany && <OnboardingCareerAndCompany 
        keywords={keywords}
        onNext={(step, value) => {
          payload.current.companyName = value?.company;
          payload.current.job = value!.job;
          const keyword = groupedKeyword['커리어'].find(keyword => keyword.name === payload.current.job)!;
          payload.current.careerKeyword = keyword;
          
          logger.info(payload.current);
          transition(() => void onNext(step));
        }}
        onPrev={() => {}}
        profile={payload.current.profile}
        step={step} />}
      {step === OnboardingStep.VerifyCompany && <VerifyCompany
        company={payload.current.companyName!}
        job={payload.current.job!}
        onNext={(step, value) => {
          if(value.email) {
            payload.current.profile.email = value.email;
          }
          transition(() => void onNext(step));
        }}
        onPrev={() => {}}
        step={step}
      />}
      {
        step === OnboardingStep.SendEmail && 
        <SendEmail 
          email={payload.current.profile.email}
          onNext={() => {
            transition(() => void onNext(OnboardingStep.FollowKeyword));
          }}
        />
      }
      {step === OnboardingStep.FollowKeyword &&
       <FollowKeywords
         careerKeyword={payload.current.careerKeyword}
         groupedKeyword={groupedKeyword}
         onNext={(step, value) => {
           const params = new URLSearchParams();
           void params.delete(GlobalDialog.Onboarding);  
           void params.delete(GlobalDialog.OnboardingStep);  
           void setSearchParams(params);
         }}
         onPrev={() => {}}
         selectedKeywords={followKeywords}
       />}
    </Modal>
  );
};


interface BaseValueInterface {
  profile: {
    imageUrl:string;
    name: string;
  };
  step: OnboardingStep;

}
interface BaesActionInterface{
  onNext: (step: OnboardingStep) => void;
  onPrev: (step: OnboardingStep) => void;
}

interface ProfileProps extends BaesActionInterface, BaseValueInterface {}
const Profile:React.FC<ProfileProps> = ({ onNext, profile }) => {

  useEffect(() => {
    void track('view_onboarding_profile');
  }, []);

  return (
    <Content size={isMobile ? 'full' : 'medium'}>
      <ModalMain className={'px-[3.75rem] py-[3.75rem]'}>
        <section className='grid grid-flow-row gap-16'>
          <div className='grid grid-flow-row gap-10 justify-items-center'>
            <img alt='profile' className='w-[5.5rem] h-[5.5rem] rounded-[1.125rem]' src={profile.imageUrl ?? ''} />
            <div className='grid grid-flow-row gap-4 text-center'>
              <span className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
                {profile.name}님,
                <br/> 
               가입을 환영해요!
              </span>
              <span className='text-body-1-paragraph-medium xs:font-sfProMedium sm:font-pretendardMedium text-gray-60'>
              신뢰할 수 있는 도서정보를 나누고 소통하기 위해 간단한
                <br/>
              질문에 답해주세요.
              </span>
            </div>
          </div>
          <Button
            data-umami-event='click_onboarding_profile_next'
            onClick={() => { onNext(OnboardingStep.CareerAndCompany); }} rounded={'md'} size={'large'} variant={'primary'}>다음</Button>
        </section>
      </ModalMain>
    </Content>
  );
};

interface VerifyCompanyProps {
  company: string;
  job: string;
  onNext: (step: OnboardingStep, value: {email?: string}) => void;
  onPrev: (step: OnboardingStep) => void;
  step: OnboardingStep;
}
const VerifyCompany: React.FC<VerifyCompanyProps> = ({
  company,
  job,
  onNext,
  onPrev,
  step
}) => {
  const [form, fields] = useForm({
    constraint: getZodConstraint(verifyCompanySchema),
    id        : OnboardingStep.VerifyCompany,
  });
  const [email, setEmail] = React.useState('');
  const [errors, setErrors] = React.useState<null[] | string[]>([]);
  const [, transition] = React.useTransition();
  React.useEffect(() => {
    const notAvailableDomains = [
      'naver.com',
      'gmail.com',
      'kakao.com',
      'nate.com',
      'google.com',
      'hanmail.net',
      'hotmail.com',
      'nate.com',
    ];
    if (email.length > 64) {
      void setErrors(['64자 이내로 입력해주세요']);
      return;
    }

    if (!new RegExp(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i).test(email) && email.includes('@')) {
      const domain = email.split('@')[1];
      if (domain.includes('.')) {
        void setErrors(['회사/소속 이메일을 입력해 주세요.']);
        return;
      }
    }

    if(email.includes('@')) {
      const domain = email.split('@')[1];
      if(notAvailableDomains.includes(domain)) {
        void setErrors(['회사/소속 이메일을 입력해 주세요.']);
        return;
      }
    }    
    void setErrors([]);
  }, [email]);

  return(
    <Content
      onInteractOutside={(e) => {
        e.preventDefault();
        return;
      }} showClose={true} size='medium'>
      <Form id={form.id} method='post' navigate={false}>
        <ModalMain className={'px-[3.75rem] py-[3.75rem] h-[32.5rem] grid'}>
          <section className='grid grid-flow-row content-between'>
            <div className='grid grid-flow-row gap-10'>
              <div className='grid grid-flow-row gap-4 text-center'>
                <h1 className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
                  {company}의
                  <br/>
                이메일 주소를 알려주세요
                </h1>
              </div>
              <Field
                errors={errors}
                inputProps={{
                  autoFocus: true,
                  onChange : (e) => {
                    const value = e.currentTarget.value;
                    void setEmail(value);
                  },
                  placeholder: 'Id@company.com',
                  value      : email,
                  ...getInputProps(fields.email, {type: 'text'}),
                }}
              />
            </div>
            <div className='grid grid-flow-row gap-5'>
              <Button
                data-umami-event='click_send_verify_company_email'
                disabled={errors.length> 0 || email.length === 0}
                onClick={() => { 
                  transition(() => onNext(OnboardingStep.SendEmail, {email,}));
                }} 
                rounded={'md'}
                size={'large'}
                type='submit'
                variant={'primary'}
              >
              인증메일 보내기
              </Button>
              <TextButton
                data-umami-event='click_send_verify_company_email_close'
                onClick={() => {
                  transition(() => onNext(OnboardingStep.FollowKeyword, {}));
                }} size={'medium'} underline={'underline'}>
              기타 이유로 인증할 수 없습니다.
              </TextButton>
            </div>
          </section>
        </ModalMain>
      </Form>
    </Content>
  );
};


interface SendEmailProps {
  email: string;
  onNext: () => void;
}
export const SendEmail: React.FC<SendEmailProps> = ({
  email,
  onNext
}) => {
  const [form, fields] = useForm({
    constraint: getZodConstraint(z.object({email: z.string().email()})),
    id        : OnboardingStep.SendEmail,
  });
  return (
    <Content showClose={true} size='medium'>
      <Form id={form.id} method='post' navigate={false}>
        <div className='h-0 hidden'>
          <Field
            inputProps={{
              value: email,
              ...getInputProps(fields.email, {type: 'hidden'}),
            }}
          />
        </div>
        <ModalMain className={'px-[3.75rem] py-[2.5rem] grid'}>
          <section className='grid grid-flow-row gap-5'>
            <div className='grid grid-flow-row gap-10'>
              <div className='grid grid-flow-row gap-4 text-center'>
                <h1 className='text-title-2-bold-desktop xs:font-sfProBold sm:font-pretendardBold'>
                인증 메일이 발송되었어요.
                </h1>
                <h2 className='text-body-1-paragraph-medium xs:font-sfProMedium sm:font-pretendardMedium text-gray-60'>
                  {email}로 인증 메일이 발송되었습니다. 
                  <br/>
              메일을 확인하고 인증을 완료해주세요. 
                </h2>
              </div>
              <div>
                <Button
                  onClick={onNext}
                  rounded={'md'}
                  size={'large'}
                  type='button'
                  variant={'primary'}
                >
                확인
                </Button>
              </div>
            </div>
            <div>
              <span className='text-body-2-regular xs:font-sfProLight sm:font-pretendardLight text-gray-90 '>
                스팸 메일함을 확인해보세요. 거기에도 없다면, &nbsp;
                <TextButton className='text-point-blue-60 text-body-2-bold xs:font-sfProBold sm:font-pretendardBold' onClick={() => {}} size={'medium'} type='submit' underline={'none'}>
                  다시 발송하기
                </TextButton>
              </span>
            </div>
          </section>
        </ModalMain>
      </Form>
    </Content>
  );
};
export const Content: React.FC<PropsWithChildren<{
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onInteractOutside?: React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>['onInteractOutside'];
  showClose?: boolean;
  size: 'full'| 'large' | 'medium' | 'small'
}>> = ({ children, onClose, onInteractOutside, showClose, size }) => {
  
  return (
    <ModalContent
      onClose={(e) => {
        if(onClose) {
          onClose(e);
        }
      }}
      onInteractOutside={(e) => {
        if (onInteractOutside) {
          void onInteractOutside(e);
          return;
        }

        if (!showClose) {
          void e.preventDefault();
          return;
        }
      }}
      showClose={showClose}
      size={size}>
      {children}
    </ModalContent>
  );
};



export default OnboardingDialog;
