import { Autocomplete, } from '#app/components/forms';
import * as React from 'react';
interface Item {
  disabled?: boolean;
  id?: number;
  label: string;
  value: string;
}
interface Props {
  className?:string;
  error?: boolean;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  items: Item[];
  loading?: boolean;
  setSelected: (item: {id: number | undefined; label:string | undefined; value:string | undefined;}, type: 'click' | 'enter') => void;
}
export function ComboBoxResponsive({
  error,
  inputProps,
  items,
  loading,
  setSelected,
}: Props) {
  const [_open, _setOpen] = React.useState(false);
  const [, transition] = React.useTransition();
  const ref = React.useRef<boolean>(inputProps.autoFocus ?? false);

  const [inputValue, setInputValue] = React.useState<string>(inputProps?.value as string ?? '');
  const [_selected, _setSelected] = React.useState<string | undefined>(inputProps?.value as string | undefined);

  return (
    <Autocomplete
      error={error ?? false}
      expanded={_open}
      inputProps={{
        ...inputProps,
        onBlur: () => {
          setTimeout(() => _setOpen(false), 100);
        },
        onChange: (value) => {
          if (!_open) {
            void _setOpen(true);
          }

          if (value.currentTarget.value === '' || !value.currentTarget.value) {
            void _setSelected(undefined);
            void setSelected({id: undefined, label: undefined, value: undefined}, 'click');
          }
          
          void setInputValue(value.currentTarget.value);
        },
        onFocus: () => {
          if (inputProps.autoFocus === false && ref.current === false) {
            ref.current = true;
            return;
          }
          transition(() => _setOpen(true));
        },
        onKeyDown: (e) => {
          if (e.key === 'Enter') {
            /** 한글 2번 입력 막아요 */
            if (e.nativeEvent.isComposing) { 
              return;
            } 
            void _setSelected(items[0]?.value);
            void setInputValue(items[0]?.label);
            void setSelected(items[0], 'enter');
          }
        },
        value: inputValue
      }}
      menuProps={{
        items      : items,
        loading,
        selected   : _selected,
        setSelected: (value, type) => {
          void _setSelected(value.value);
          void setSelected(value, type);
          void setInputValue(value.label ?? '');
          void _setOpen(false);
        }
      }}
    />
  );
  // return (
  //   <Drawer onOpenChange={setOpen} open={open}>
  //     <DrawerTrigger asChild>
  //       <Button className="w-[150px] justify-start" variant="gray">
  //         {selectedStatus ? <>{selectedStatus.label}</> : <>+ Set status</>}
  //       </Button>
  //     </DrawerTrigger>
  //     <DrawerContent>
  //       <div className="mt-4 border-t">
  //         <StatusList setOpen={setOpen} setSelectedStatus={setSelectedStatus} />
  //       </div>
  //     </DrawerContent>
  //   </Drawer>
  // );
}

// function StatusList({
//   setOpen,
//   setSelectedStatus,
// }: {
//   setOpen: (open: boolean) => void
//   setSelectedStatus: (status: Status | null) => void
// }) {
//   return (
//     <Command>
//       <CommandList>
//         <CommandEmpty>No results found.</CommandEmpty>
//         <CommandGroup>
//           {statuses.map((status) => (
//             <CommandItem
//               key={status.value}
//               onSelect={(value) => {
//                 setSelectedStatus(
//                   statuses.find((priority) => priority.value === value) || null
//                 );
//                 setOpen(false);
//               }}
//               value={status.value}
//             >
//               {status.label}
//             </CommandItem>
//           ))}
//         </CommandGroup>
//       </CommandList>
//     </Command>
//   );
// }
