
import type { VariantProps} from 'class-variance-authority';

import { cn } from '#app/utils/misc';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { cva } from 'class-variance-authority';
import * as React from 'react';

import { Icon } from '../icon';
import Image from '../img/image';

const Modal = DialogPrimitive.Root;

const ModalTrigger = DialogPrimitive.Trigger;

const ModalPortal = DialogPrimitive.Portal;

const ModalClose = DialogPrimitive.Close;

const ModalOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    className={cn(
      'fixed inset-0 z-50 backdrop-blur-sm bg-black/50 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0',
      className
    )}
    ref={ref}
    {...props}
  />
));

ModalOverlay.displayName = DialogPrimitive.Overlay.displayName;

const modalContentVariants = cva(
  'fixed left-[50%] top-[50%] z-50 grid grid-flow-row w-full translate-x-[-50%] translate-y-[-50%] bg-background shadow-lg duration-500 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] rounded-2.5xl',
  {
    defaultVariants: { 
      size   : 'large',
      variant: 'card',
    },
    variants: {
      size: {
        full  : 'w-full h-full',
        large : 'w-[50rem] min-h-[37.5rem]',
        medium: 'grid-rows-[auto, 1fr] w-[29.25rem]',
        small : 'w-[20.625rem]',
      },
      variant: {
        alert : '',
        card  : '',
        submit: '',
      },
    }
  },
);


const ModalContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  {
    onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    showClose?: boolean;
      }
      & React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & VariantProps<typeof modalContentVariants>
      >(({ children, className, onClose, showClose, ...props }, ref) => (
        <ModalPortal>
          <ModalOverlay  />
          <DialogPrimitive.Content
            className={cn(
              modalContentVariants(props),
              className,
            )}
            ref={ref}
            {...props}
          >
            {children}
            {showClose && 
            <DialogPrimitive.Close className="absolute right-4 top-4 grid bg-gray-10 p-2 hover:bg-gray-20 rounded-full opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
              onClick={(e) => {
                if(onClose) {
                  onClose(e);
                }
              }}>
              <Icon className='fill-gray-60' name='regular-close' size='small' />
            </DialogPrimitive.Close>
            }
          </DialogPrimitive.Content>
        </ModalPortal>
      ));
ModalContent.displayName = DialogPrimitive.Content.displayName;


const ModalMain = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <div
    className={cn(
      className,
    )}
    ref={ref}
    {...props}
  >
    {props.children}
  </div>
));

// const ModalMain = ({
//   className,
//   ...props
// }: React.HTMLAttributes<HTMLDivElement>) => (
//   <div
//     className={cn(
//       className,
//     )}
//     {...props}
//   >
//     {props.children}
//   </div>
// );

ModalMain.displayName = 'ModalMain';


const ModalHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col mt-10 mx-10 mb-5',
      className
    )}
    {...props}
  />
);
ModalHeader.displayName = 'ModalHeader';

const ModalCardImage = ({
  alt,
  className, lazy, src,
}: {
  alt?: string;
  lazy?: boolean;
  src?: string;
} & React.HtmlHTMLAttributes<HTMLImageElement>) => (
  <Image alt={alt || 'alt image'}
    className={
      cn(
        'h-[16.25rem] aspect-square object-cover w-full',
        className,
      )
    } 
    lazy={lazy ?? false} src={src || 'https://placehold.co/600x400'} />
);
ModalCardImage.displayName = 'ModalCardImage';

const ModalFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      'flex flex-col-reverse sm:flex-row sm:justify-end pt-4 px-5 pb-5 border-t-gray-20 border-solid border-t',
      className
    )}
    {...props}
  />
);
ModalFooter.displayName = 'ModalFooter';

const ModalTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    className={cn(
      'text-title-1-bold-desktop xs:font-sfProBold sm:font-pretendardBold text-gray-90',
      className
    )}
    ref={ref}
    {...props}
  />
));
ModalTitle.displayName = DialogPrimitive.Title.displayName;

export const EnhancedModal: React.FC<React.PropsWithChildren<{
  onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onInteractOutside?: React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>['onInteractOutside'];
  showClose?: boolean;
  size: 'full'| 'large' | 'medium' | 'small'
}>> = ({ children, onClose, onInteractOutside, showClose, size }) => {
  
  return (
    <ModalContent
      onClose={(e) => {
        if(onClose) {
          onClose(e);
        }
      }}
      onInteractOutside={(e) => {
        if (onInteractOutside) {
          void onInteractOutside(e);
          return;
        }

        if (!showClose) {
          void e.preventDefault();
          return;
        }
      }}
      showClose={showClose}
      size={size}>
      {children}
    </ModalContent>
  );
};



export {
  Modal,
  ModalCardImage,
  ModalClose,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain, ModalOverlay,
  ModalPortal,
  ModalTitle, ModalTrigger,
};
