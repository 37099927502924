import { prisma } from '#app/utils/db.server';
import { logger } from '#app/utils/logger';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

export const careerAndCompanySchema = z.object({
  company   : z.string().min(2).max(20).optional(),
  displayJob: z.string().max(20).optional(),
  job       : z.string(),
});

/**
 * @description 경력 및 회사 정보를 업데이트하는 액션
 * @param userId 가입한 유저 아이디
 * @param formData 폼 데이터
 */
export const careerAndCompanyAction = async (userId: number, formData: FormData) => {
  logger.info('careerAndCompanyAction called!');
  const response = await parseWithZod(formData, {
    async : true,
    schema: careerAndCompanySchema.superRefine(async (data, ctx) => {
      const keyword = await prisma.keyword.findFirst({
        where: {
          name: data.job,
        },
      });


      if (keyword === null) {
        ctx.addIssue({
          code   : 'custom',
          message: '존재하지 않는 직업입니다.',
        });
        return ctx;
      }
      
      await prisma.user.update({
        data: {
          careerKeyword: {
            connect: {
              id: keyword.id,
            }
          },
          displayCareer     : data.displayJob,
          workingCompanyName: data.company
        },
        where: { id: userId },
      });
    }),
  },);


  return response.status;
};