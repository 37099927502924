import { prisma } from '#app/utils/db.server';
import { logger } from '#app/utils/logger';
import { z } from 'zod';

export const followKeywordSchema = z.object({
  followKeywords: z.array(z.object({
    id: z.number(),
  })),
});

/**
 */
export const followKeywordAction = async (userId: number, keywordIds: number[]) => {
  logger.info('followKeywordAction called!');
  await prisma.followKeyword.deleteMany({
    where: {
      userId: userId,
    },
  });
  
  await prisma.followKeyword.createMany(
    {
      data: keywordIds.filter(id => id !== 0).map((id) => {
        return {
          keywordId: id,
          userId   : userId,
        };
      }),
      skipDuplicates: true,
    }
  );
};