import { createOrUpdateCompanyHistory, prepareVerification } from '#app/routes/company+/verify';
import { prisma } from '#app/utils/db.server';
import { sendEmail } from '#app/utils/email.server';
import { logger } from '#app/utils/logger';
import { parseWithZod } from '@conform-to/zod';
import { z } from 'zod';

export const verifyCompanySchema = z.object({
  email: z.string().email().max(100).optional(),
});

export const verifyCompanyAction = async (userId: number, formData: FormData, request: Request) => {
  console.log('verifyCompanyAction called!');
  await parseWithZod(formData, {
    async : true,
    schema: verifyCompanySchema.superRefine(async (data) => {
      const user = await prisma.user.findUniqueOrThrow({
        where: {
          id: userId,
        },
      });

      const { verifyUrl } = await prepareVerification({
        period: 10 * 60,
        request,
        target: data.email!,
        type  : 'verify-company',
        userId: user.id,
      });

      await createOrUpdateCompanyHistory({
        email             : data.email!,
        id                : user.id,
        workingCompanyName: user.workingCompanyName,
      });

      logger.error(verifyUrl.toString());

      await sendEmail({
        html: `
              <img src="https://bookcovery-web-images.s3.ap-northeast-2.amazonaws.com/company/bookcovery-1x.png"><br/>
              <br/>
              안녕하세요 ${user.name ?? user.email}님<br/>
              아래 버튼을 눌러 회사 계정 인증을 완료해주세요.<br/>
              <br/>
              <a href="${verifyUrl.toString()}" target="_blank">인증 완료하고 페이지 돌아가기</a><br/><br/>
              <br/>
              -<br/>
              (주)북커버리
        `,
        subject: 'Bookcovery 회사정보 인증 이메일',
        text   : 'Bookcovery 회사정보 인증 이메일',
        to     : data.email!,
      });
    }),
  });
};
